export default async function sendFormDataJson(e, method) {
  let resp = await fetch(e.target.action, {
    method: method || "post",
    body: JSON.stringify(Object.fromEntries(new FormData(e.target))),
    headers: {
      "Content-Type": "application/json",
    },
  });
  return resp;
}
