"use client";

import { useState, useEffect } from "react";
import {
  Dialog,
  DialogBackdrop,
  DialogPanel,
  Disclosure,
  DisclosureButton,
  DisclosurePanel,
  Popover,
  PopoverButton,
  PopoverGroup,
  PopoverPanel,
} from "@headlessui/react";
import { XMarkIcon } from "@heroicons/react/24/outline";
import { ChevronDownIcon } from "@heroicons/react/20/solid";

const sortOptions = [
  { name: "Most Popular", href: "#", current: true },
  { name: "Best Rating", href: "#", current: false },
  { name: "Newest", href: "#", current: false },
];
const activeFilters = [{ value: "objects", label: "Objects" }];

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

export function RessourceFilter({ team, pushFilterUpdate, availableVariants }) {
  const [open, setOpen] = useState(false);

  const filters = [
    {
      id: "bookings",
      name: "Buchungen",
      options: [
        {
          value: "withBookings",
          label: "Mit Buchungen",
          checked: false,
        },
        {
          value: "withoutBookings",
          label: "Ohne Buchungen",
          checked: false,
        },
      ],
    },
    {
      id: "team",
      name: "Team",
      options: team
        ? team.map((member) => {
            return {
              value: member.staff_name,
              label: member.staff_name,
              checked: false,
            };
          })
        : [],
    },
    {
      id: "variants",
      name: "Variante",
      options: availableVariants.map((v) => {
        return { value: v.name, label: v.name, checked: false };
      }),
    },
  ];
  function handleFilterChange(evt) {
    if (evt.target.checked) {
      setSelectedFilters({
        ...selectedFilters,
        [evt.target.name]: [
          ...selectedFilters[evt.target.name],
          evt.target.value,
        ],
      });
    } else {
      setSelectedFilters({
        ...selectedFilters,
        [evt.target.name]: selectedFilters[evt.target.name].filter(
          (val) => val !== evt.target.value
        ),
      });
    }
  }

  const _selectedFilters = filters.reduce((acc, curr) => {
    acc[curr.id] = curr.options.filter((o) => o.checked).map((o) => o.value);
    return acc;
  }, {});

  const [selectedFilters, setSelectedFilters] = useState(_selectedFilters);
  useEffect(() => {
    setSelectedFilters(
      filters.reduce((acc, curr) => {
        acc[curr.id] = curr.options
          .filter((o) => o.checked)
          .map((o) => o.value);
        return acc;
      }, {})
    );
  }, [team, availableVariants]);
  pushFilterUpdate(selectedFilters);

  return (
    <div className="bg-white">
      {/* Mobile filter dialog */}
      <Dialog open={open} onClose={setOpen} className="relative z-40 sm:hidden">
        <DialogBackdrop
          transition
          className="fixed inset-0 bg-black bg-opacity-25 transition-opacity duration-300 ease-linear data-[closed]:opacity-0"
        />

        <div className="fixed inset-0 z-40 flex">
          <DialogPanel
            transition
            className="relative ml-auto flex h-full w-full max-w-xs transform flex-col overflow-y-auto bg-white py-4 pb-12 shadow-xl transition duration-300 ease-in-out data-[closed]:translate-x-full"
          >
            <div className="flex items-center justify-between px-4">
              <h2 className="text-lg font-medium text-gray-900">Filters</h2>
              <button
                type="button"
                onClick={() => setOpen(false)}
                className="-mr-2 flex h-10 w-10 items-center justify-center rounded-md bg-white p-2 text-gray-400"
              >
                <span className="sr-only">Close menu</span>
                <XMarkIcon aria-hidden="true" className="h-6 w-6" />
              </button>
            </div>

            {/* Filters */}
            <form className="mt-4">
              {filters.map((section) => (
                <Disclosure
                  key={section.name}
                  as="div"
                  className="border-t border-gray-200 px-4 py-6"
                >
                  <h3 className="-mx-2 -my-3 flow-root">
                    <DisclosureButton className="group flex w-full items-center justify-between bg-white px-2 py-3 text-sm text-gray-400">
                      <span className="font-medium text-gray-900">
                        {section.name}
                      </span>
                      <span className="ml-6 flex items-center">
                        <ChevronDownIcon
                          aria-hidden="true"
                          className="h-5 w-5 rotate-0 transform group-data-[open]:-rotate-180"
                        />
                      </span>
                    </DisclosureButton>
                  </h3>
                  <DisclosurePanel className="pt-6">
                    <div className="space-y-6">
                      {section.options.map((option, optionIdx) => (
                        <div key={option.value} className="flex items-center">
                          <input
                            onChange={handleFilterChange}
                            defaultValue={option.value}
                            defaultChecked={option.checked}
                            checked={selectedFilters[section.id].includes(
                              option.value
                            )}
                            id={`filter-mobile-${section.id}-${optionIdx}`}
                            name={section.id}
                            type="checkbox"
                            className="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-500"
                          />
                          <label
                            htmlFor={`filter-mobile-${section.id}-${optionIdx}`}
                            className="ml-3 text-sm text-gray-500"
                          >
                            {option.label}
                          </label>
                        </div>
                      ))}
                    </div>
                  </DisclosurePanel>
                </Disclosure>
              ))}
            </form>
          </DialogPanel>
        </div>
      </Dialog>

      {/* Filters */}
      <section aria-labelledby="filter-heading">
        <h2 id="filter-heading" className="sr-only">
          Filters
        </h2>

        <div className="bg-white">
          <div className="mx-auto flex max-w-7xl items-center justify-end px-4 sm:px-6 lg:px-8">
            <button
              type="button"
              onClick={() => setOpen(true)}
              className="inline-block text-sm font-medium text-gray-700 hover:text-gray-900 sm:hidden"
            >
              Filter
            </button>

            <div className="hidden sm:block">
              <div className="flow-root">
                <PopoverGroup className="-mx-4 flex items-center divide-x divide-gray-200">
                  {filters.map((section, sectionIdx) => (
                    <Popover
                      key={section.name}
                      className="relative inline-block px-4 text-left"
                    >
                      <PopoverButton className="group inline-flex justify-center text-sm font-medium text-gray-700 hover:text-gray-900">
                        <span>{section.name}</span>
                        {selectedFilters[section.id].length > 0 ? (
                          <span className="ml-1.5 rounded bg-gray-200 px-1.5 py-0.5 text-xs font-semibold tabular-nums text-gray-700">
                            {selectedFilters[section.id].length}
                          </span>
                        ) : null}
                        <ChevronDownIcon
                          aria-hidden="true"
                          className="-mr-1 ml-1 h-5 w-5 flex-shrink-0 text-gray-400 group-hover:text-gray-500"
                        />
                      </PopoverButton>

                      <PopoverPanel
                        transition
                        className="absolute right-0 z-10 mt-2 origin-top-right rounded-md bg-white p-4 shadow-2xl ring-1 ring-black ring-opacity-5 transition focus:outline-none data-[closed]:scale-95 data-[closed]:transform data-[closed]:opacity-0 data-[enter]:duration-100 data-[leave]:duration-75 data-[enter]:ease-out data-[leave]:ease-in"
                      >
                        <form className="space-y-4">
                          {section.options.map((option, optionIdx) => (
                            <div
                              key={option.value}
                              className="flex items-center"
                            >
                              <input
                                defaultValue={option.value}
                                defaultChecked={option.checked}
                                checked={selectedFilters[section.id].includes(
                                  option.value
                                )}
                                onChange={handleFilterChange}
                                id={`filter-${section.id}-${optionIdx}`}
                                name={section.id}
                                type="checkbox"
                                className="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-500"
                              />
                              <label
                                htmlFor={`filter-${section.id}-${optionIdx}`}
                                className="ml-3 whitespace-nowrap pr-6 text-sm font-medium text-gray-900"
                              >
                                {option.label}
                              </label>
                            </div>
                          ))}
                        </form>
                      </PopoverPanel>
                    </Popover>
                  ))}
                </PopoverGroup>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}
