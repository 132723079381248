import { Dialog } from "@headlessui/react";
import NiceModal, { useModal } from "@ebay/nice-modal-react";

export default NiceModal.create(({ variants, returnFormData }) => {
  const modal = useModal();
  function handleSubmit(evt) {
    evt.preventDefault();

    returnFormData(Object.fromEntries(new FormData(evt.target)));
    modal.hide();
  }
  function handleCancelButton(evt) {
    console.log(evt);
    modal.hide();
  }
  return (
    <Dialog
      open={modal.visible}
      onClose={() => {
        modal.hide();
      }}
    >
      <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"></div>

      <div className="fixed inset-0 z-10 overflow-y-auto">
        <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
          <div
            className="relative transform overflow-hidden rounded-lg bg-white px-4 pt-5 pb-4 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-sm sm:p-6"
            id="adduse-modal-panel"
          >
            <div>
              <form className="mt-8 space-y-6" onSubmit={handleSubmit}>
                {variants &&
                  variants.map((v, index) => (
                    <div key={v}>
                      <input
                        name="category"
                        type={variants.length > 1 ? "radio" : "hidden"}
                        value={v}
                        id={"newbooking-" + v}
                        required
                      />
                      <label htmlFor={"newbooking-" + v}>{v}</label>
                    </div>
                  ))}

                <div className="-space-y-px rounded-md shadow-sm">
                  <div className="mt-6 grid grid-cols-1 gap-y-6 gap-x-4 sm:grid-cols-6">
                    <div className="col-span-6">
                      <label htmlFor="newbooking-name">Name</label>
                      <input
                        id="newbooking-name"
                        name="booker_name"
                        type="text"
                        required
                        className="relative block w-full appearance-none rounded--md border border-gray-300 px-3 py-2 text-gray-900 placeholder-gray-500 focus:z-10 focus:border-stone-500 focus:outline-none focus:ring-stone-500 sm:text-sm"
                      />
                    </div>
                    <div className="col-span-6">
                      <label htmlFor="newbooking-email">E-Mail-Adresse</label>
                      <input
                        id="newbooking-email"
                        name="booker_email"
                        type="email"
                        required
                        className="relative block w-full appearance-none rounded--md border border-gray-300 px-3 py-2 text-gray-900 placeholder-gray-500 focus:z-10 focus:border-stone-500 focus:outline-none focus:ring-stone-500 sm:text-sm"
                      />
                    </div>
                    <div className="col-span-6">
                      <label htmlFor="newbooking-count">Anzahl</label>
                      <input
                        id="newbooking-count"
                        name="attendees_count"
                        type="number"
                        min="1"
                        required
                        className="relative block w-full appearance-none rounded--md border border-gray-300 px-3 py-2 text-gray-900 placeholder-gray-500 focus:z-10 focus:border-stone-500 focus:outline-none focus:ring-stone-500 sm:text-sm"
                      />
                    </div>
                    <div className="col-span-6">
                      <label htmlFor="newbooking-comment">Anmerkung</label>
                      <textarea
                        id="newbooking-comment"
                        name="comment"
                        type="textarea"
                        rows="4"
                        className="relative block w-full appearance-none rounded--md border border-gray-300 px-3 py-2 text-gray-900 placeholder-gray-500 focus:z-10 focus:border-stone-500 focus:outline-none focus:ring-stone-500 sm:text-sm"
                      />
                    </div>
                  </div>
                </div>

                <div className="space-y-1">
                  <button
                    type="submit"
                    className="group relative flex w-full justify-center rounded-md border border-transparent bg-green-600 py-2 px-4 text-sm font-medium text-white hover:bg-stone-700 focus:outline-none focus:ring-2 focus:ring-stone-500 focus:ring-offset-2"
                  >
                    Speichern
                  </button>
                  <button
                    type="button"
                    onClick={handleCancelButton}
                    className="group relative flex w-full justify-center rounded-md border border-transparent bg-red-800 py-2 px-4 text-sm font-medium text-white hover:bg-stone-700 focus:outline-none focus:ring-2 focus:ring-stone-500 focus:ring-offset-2"
                  >
                    Beenden
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </Dialog>
  );
});
