import { useState, useEffect, useContext } from "react";
import { Menu, MenuButton, MenuItem, MenuItems } from "@headlessui/react";
import { UserPlusIcon } from "@heroicons/react/20/solid";
import sendFormDataJson from "../utils/sendFormDataJson";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

export async function getTeam() {
  return await (
    await fetch("/api/admin/staff", { credentials: "same-origin" })
  ).json();
}

function EditUserModal({ setShowAddUserDialog, addUserToList }) {
  const [username, setUsername] = useState("");
  async function handleSubmit(evt) {
    evt.preventDefault();
    let newUser = await (await sendFormDataJson(evt, evt.target)).json();
    console.log(newUser);
    addUserToList(newUser);
    setShowAddUserDialog(false);
  }
  function suggestUsername(evt) {
    setUsername(evt.target.value.replace(/\s/g, "").toLowerCase());
  }
  function handleCancelButton() {
    setShowAddUserDialog(false);
  }

  return (
    <div
      className="relative z-10"
      aria-labelledby="modal-title"
      role="dialog"
      aria-modal="true"
    >
      <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"></div>

      <div className="fixed inset-0 z-10 overflow-y-auto">
        <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
          <div
            className="relative transform overflow-hidden rounded-lg bg-white px-4 pt-5 pb-4 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-sm sm:p-6"
            id="adduse-modal-panel"
          >
            <div>
              <div className="mx-auto flex h-12 w-12 items-center justify-center rounded-full bg-green-100"></div>
              <form
                className="mt-8 space-y-6"
                action="/api/admin/users"
                method="POST"
                onSubmit={handleSubmit}
              >
                <div className="-space-y-px rounded-md shadow-sm">
                  <div className="mt-6 grid grid-cols-1 gap-y-6 gap-x-4 sm:grid-cols-6">
                    <div className="col-span-6">
                      <label htmlFor="name">Name*</label>
                      <input
                        id="name"
                        name="name"
                        type="text"
                        required
                        className="relative block w-full appearance-none rounded--md border border-gray-300 px-3 py-2 text-gray-900 placeholder-gray-500 focus:z-10 focus:border-stone-500 focus:outline-none focus:ring-stone-500 sm:text-sm"
                        onChange={suggestUsername}
                      />
                    </div>
                    <div className="col-span-6">
                      <label htmlFor="username">Benutzername*</label>
                      <input
                        id="username"
                        name="username"
                        type="text"
                        required
                        value={username}
                        className="relative block w-full appearance-none rounded--md border border-gray-300 px-3 py-2 text-gray-900 placeholder-gray-500 focus:z-10 focus:border-stone-500 focus:outline-none focus:ring-stone-500 sm:text-sm"
                      />
                    </div>
                    <div className="col-span-6">
                      <label htmlFor="email">E-Mail-Adresse*</label>
                      <input
                        id="email"
                        name="email"
                        type="email"
                        required
                        className="relative block w-full appearance-none rounded--md border border-gray-300 px-3 py-2 text-gray-900 placeholder-gray-500 focus:z-10 focus:border-stone-500 focus:outline-none focus:ring-stone-500 sm:text-sm"
                      />
                    </div>
                    <div className="col-span-6">
                      <label htmlFor="new_user_password">Passwort*</label>
                      <input
                        id="new_user_password"
                        name="password"
                        type="password"
                        min="1"
                        required
                        className="relative block w-full appearance-none rounded--md border border-gray-300 px-3 py-2 text-gray-900 placeholder-gray-500 focus:z-10 focus:border-stone-500 focus:outline-none focus:ring-stone-500 sm:text-sm"
                      />
                    </div>
                    <div className="relative flex items-start">
                      <div className="flex h-5 items-center">
                        <input
                          id="new_user_admin"
                          name="admin"
                          type="checkbox"
                          className="h-4 w-4 rounded border-gray-300 text-orange-600 focus:ring-orange-500"
                        />
                      </div>
                      <div className="ml-3 text-sm">
                        <label
                          className="font-medium text-gray-700"
                          htmlFor="new_user_admin"
                        >
                          Admin
                        </label>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="space-y-1">
                  <button
                    type="submit"
                    className="group relative flex w-full justify-center rounded-md border border-transparent bg-green-600 py-2 px-4 text-sm font-medium text-white hover:bg-stone-700 focus:outline-none focus:ring-2 focus:ring-stone-500 focus:ring-offset-2"
                  >
                    Speichern
                  </button>
                  <button
                    type="button"
                    onClick={handleCancelButton}
                    className="group relative flex w-full justify-center rounded-md border border-transparent bg-red-800 py-2 px-4 text-sm font-medium text-white hover:bg-stone-700 focus:outline-none focus:ring-2 focus:ring-stone-500 focus:ring-offset-2"
                  >
                    Beenden
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export function UserList() {
  const [team, setTeam] = useState([]);
  const [showAddUserDialog, setShowAddUserDialog] = useState(false);

  function handleAddUserButton() {
    setShowAddUserDialog(true);
  }
  function addUserToList(newUser) {
    setTeam([...team, newUser]);
  }
  async function handleUserDelete(user_id) {
    const response = await fetch("/api/admin/users/" + user_id, {
      method: "DELETE",
    });
    if (response.ok) {
      setTeam(team.filter((member) => member.staff_id !== user_id));
    } else {
      alert("Es ist ein Fehler beim Löschen des Benutzers aufgetreten.");
      console.error(response);
    }
  }
  useEffect(() => {
    (async () => {
      setTeam(await getTeam());
    })();
  }, []);

  return (
    <>
      {showAddUserDialog && (
        <EditUserModal
          setShowAddUserDialog={setShowAddUserDialog}
          addUserToList={addUserToList}
        />
      )}
      <button
        type="button"
        onClick={handleAddUserButton}
        className="inline-flex items-center gap-x-1.5 rounded-md bg-orange-500 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
      >
        <UserPlusIcon aria-hidden="true" className="-ml-0.5 h-5 w-5" />
        Benutzer hinzufügen
      </button>
      <ul role="list" className="divide-y divide-gray-100">
        {team.map((member) => (
          <li
            key={member.staff_id}
            className="flex items-center justify-between gap-x-6 py-5"
          >
            <div className="min-w-0">
              <div className="flex items-start gap-x-3">
                <p className="text-sm font-semibold leading-6 text-gray-900">
                  {member.staff_name}
                </p>
                {member.admin && (
                  <p
                    className={classNames(
                      "text-yellow-800 bg-yellow-50 ring-yellow-600/20 mt-0.5 whitespace-nowrap rounded-md px-1.5 py-0.5 text-xs font-medium ring-1 ring-inset"
                    )}
                  >
                    Admin
                  </p>
                )}
              </div>
              <div className="mt-1 flex items-center gap-x-2 text-xs leading-5 text-gray-500">
                <p className="whitespace-nowrap">{member.username}</p>
                <svg viewBox="0 0 2 2" className="h-0.5 w-0.5 fill-current">
                  <circle r={1} cx={1} cy={1} />
                </svg>
              </div>
            </div>
            <div className="flex flex-none items-center gap-x-4">
              <button
                onClick={() => handleUserDelete(member.staff_id)}
                className="hidden rounded-md bg-white px-2.5 py-1.5 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:block"
              >
                Löschen
              </button>
            </div>
          </li>
        ))}
      </ul>
    </>
  );
}
