import { Dialog, DialogPanel } from "@headlessui/react";
import NiceModal, { useModal } from "@ebay/nice-modal-react";

export default NiceModal.create(({ user }) => {
  const modal = useModal();
  async function handleSubmit(evt) {
    evt.preventDefault();
    let data = Object.fromEntries(new FormData(evt.target));
    if (data.password_new !== data.password_new_confirm) {
      alert("Passwörter stimmen nicht überein");
      return;
    }
    let resp = await fetch(evt.target.action, {
      method: "POST",
      body: JSON.stringify(data),
      headers: {
        "Content-Type": "application/json",
      },
    });
    if (resp.ok) {
      modal.remove();
    }
    if (resp.status === 401) {
      alert("'Aktuelles Passwort' stimmt nicht mit Ihrem Passwort überein");
    }
  }
  return (
    <Dialog
      open={modal.visible}
      onClose={() => {
        modal.remove();
      }}
    >
      <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"></div>

      <div className="fixed inset-0 z-10 overflow-y-auto">
        <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
          <DialogPanel>
            <div
              className="relative transform overflow-hidden rounded-lg bg-white px-4 pt-5 pb-4 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-xl sm:p-6"
              id="adduse-modal-panel"
            >
              <div>
                <form
                  className="mt-8 space-y-6"
                  action="/api/password"
                  method="POST"
                  onSubmit={handleSubmit}
                >
                  <div className="-space-y-px rounded-md shadow-sm">
                    <div className="mt-6 grid grid-cols-1 gap-y-6 gap-x-4 sm:grid-cols-6">
                      <div className="col-span-6">
                        <label htmlFor="password_old">
                          Aktuelles Passwort*
                        </label>
                        <input
                          type="text"
                          name="username"
                          value={user.username}
                          className="hidden"
                        />
                        <input
                          id="password_old"
                          name="password_old"
                          type="password"
                          minlength="8"
                          autocomplete="current-password"
                          required
                          className="relative block w-full appearance-none rounded--md border border-gray-300 px-3 py-2 text-gray-900 placeholder-gray-500 focus:z-10 focus:border-stone-500 focus:outline-none focus:ring-stone-500 sm:text-sm"
                        />
                      </div>
                      <div className="col-span-6">
                        <label htmlFor="short_description">
                          Neues Passwort*
                        </label>
                        <input
                          id="password_new"
                          name="password_new"
                          type="password"
                          minlength="8"
                          autocomplete="new-password"
                          required
                          className="relative block w-full appearance-none rounded--md border border-gray-300 px-3 py-2 text-gray-900 placeholder-gray-500 focus:z-10 focus:border-stone-500 focus:outline-none focus:ring-stone-500 sm:text-sm"
                        />
                        <p className="mt-3 text-sm leading-6 text-gray-600">
                          Das neue Passwort muss mindestens 8 Zeichen lang sein
                          und sollte auch Ziffern/Sonderzeichen enthalten.
                        </p>
                      </div>
                      <div className="col-span-6">
                        <label htmlFor="short_description">
                          Neues Passwort*
                        </label>
                        <input
                          id="password_new_confirm"
                          name="password_new_confirm"
                          type="password"
                          autocomplete="new-password"
                          required
                          className="relative block w-full appearance-none rounded--md border border-gray-300 px-3 py-2 text-gray-900 placeholder-gray-500 focus:z-10 focus:border-stone-500 focus:outline-none focus:ring-stone-500 sm:text-sm"
                        />
                      </div>
                    </div>
                  </div>

                  <div className="space-y-1">
                    <button
                      type="submit"
                      className="group relative flex w-full justify-center rounded-md border border-transparent bg-green-600 py-2 px-4 text-sm font-medium text-white hover:bg-stone-700 focus:outline-none focus:ring-2 focus:ring-stone-500 focus:ring-offset-2"
                    >
                      Speichern
                    </button>
                    <button
                      type="button"
                      onClick={() => modal.remove()}
                      className="group relative flex w-full justify-center rounded-md border border-transparent bg-red-800 py-2 px-4 text-sm font-medium text-white hover:bg-stone-700 focus:outline-none focus:ring-2 focus:ring-stone-500 focus:ring-offset-2"
                    >
                      Beenden
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </DialogPanel>
        </div>
      </div>
    </Dialog>
  );
});
