import { useState, useEffect } from "react";
import { Dialog, DialogPanel } from "@headlessui/react";
import NiceModal, { useModal } from "@ebay/nice-modal-react";
import { CalendarDaysIcon } from "@heroicons/react/24/outline";

import sendFormData from "../utils/sendFormData";

export default NiceModal.create(({ ressource, addRessourceToState }) => {
  //function AddTimeslotDialog({ handleClose, addRessourceToList }) {
  let [selectedTime, setSelectedTime] = useState(
    ressource?.start || new Date(Math.ceil(Date.now() / 3600000 + 1) * 3600000)
  );
  let [selectedRessourceTypes, setSelectedRessourceTypes] = useState([]);
  const [availableRessourceTypes, setAvailableRessourceTypes] = useState([]);
  const [createTimeslotResponse, setCreateTimeslotResponse] = useState(null);
  const modal = useModal();

  useEffect(() => {
    (async () => {
      const response = await fetch("/api/admin/variants", {
        credentials: "same-origin",
      });
      if (response.ok) {
        const data = await response.json();
        setAvailableRessourceTypes(data);
      }
    })();
  }, []);

  async function handleSubmit(evt) {
    evt.preventDefault();
    if (selectedRessourceTypes.length === 0) {
      alert("Wähle mindestens einen Führungstyp aus!");
    } else {
      const submitURL = ressource
        ? evt.target.action + "/" + ressource.slot_id
        : evt.target.action;
      let response = await fetch(submitURL, {
        method: ressource ? "PUT" : "POST",
        body: new FormData(evt.target),
      });
      setCreateTimeslotResponse(response);
      addRessourceToState({
        slot_id: (await response.json()).add_timeslot,
        start: new Date(selectedTime),
        max_attendees: 10,
        sum: null,
        assigned_staff: [],
        bookings: [null],
        comment: null,
        available_ressources: selectedRessourceTypes,
        // TODO: internal: evt.target...
      });
      if (ressource) {
        modal.remove();
      } else {
        setTimeout(() => setCreateTimeslotResponse(null), 3000);
        const dt = new Date(new Date(selectedTime).getTime() + 60 * 60 * 1000);
        dt.setMinutes(dt.getMinutes() - dt.getTimezoneOffset());
        setSelectedTime(dt); //.toISOString().slice(0, 16));
      }
    }
  }

  function handleRessourceTypeChange(evt) {
    if (evt.target.checked) {
      setSelectedRessourceTypes([...selectedRessourceTypes, evt.target.value]);
    } else {
      setSelectedRessourceTypes(
        selectedRessourceTypes.filter((val) => (val = !evt.target.value))
      );
    }
  }

  function dateToInput(dt) {
    let d = new Date(dt);
    d.setMinutes(dt.getMinutes() - dt.getTimezoneOffset());
    return d.toISOString().slice(0, 16);
  }

  function handleDateTimeChange(evt) {
    if (!evt.target["validity"].valid) return;
    const dt = evt.target["value"];
    setSelectedTime(new Date(dt));
  }

  return (
    <Dialog
      open={modal.visible}
      onClose={() => {
        modal.remove();
      }}
    >
      <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"></div>

      <div className="fixed inset-0 z-10 overflow-y-auto">
        <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
          <div
            className="relative transform overflow-hidden rounded-lg bg-white px-4 pt-5 pb-4 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-sm sm:p-6"
            id="login-modal-panel"
          >
            <div>
              <div className="mx-auto flex h-12 w-12 items-center justify-center rounded-full bg-green-100">
                <CalendarDaysIcon />
              </div>
              <form
                className="mt-8 space-y-6"
                action="/api/admin/timeslot"
                method="POST"
                onSubmit={handleSubmit}
              >
                <div className="-space-y-px rounded-md shadow-sm">
                  <div className="mt-6 grid grid-cols-1 gap-y-6 gap-x-4 sm:grid-cols-6">
                    <div className="col-span-4">
                      <label htmlFor="starttime">Zeit</label>
                      <input
                        id="starttime"
                        name="starttime"
                        type="datetime-local"
                        min={new Date().toLocaleDateString("fr-ca")}
                        required
                        className="relative block w-full appearance-none rounded--md border border-gray-300 px-3 py-2 text-gray-900 placeholder-gray-500 focus:z-10 focus:border-stone-500 focus:outline-none focus:ring-stone-500 sm:text-sm"
                        onChange={handleDateTimeChange}
                        value={dateToInput(selectedTime)}
                        disabled={ressource}
                      />
                    </div>
                    <div className="col-span-2">
                      <label htmlFor="max_attendees">Max. Teiln.</label>
                      <input
                        id="max_attendees"
                        name="max_attendees"
                        type="number"
                        min="1"
                        defaultValue={ressource?.max_attendees || 10}
                        required
                        className="relative block w-full appearance-none rounded--md border border-gray-300 px-3 py-2 text-gray-900 placeholder-gray-500 focus:z-10 focus:border-stone-500 focus:outline-none focus:ring-stone-500 sm:text-sm"
                      />
                    </div>
                  </div>
                  <fieldset className="space-y-2 border-b border-gray-900/10 pb-3">
                    <legend className="sr-only">Verfügbare Varianten</legend>
                    {availableRessourceTypes.map((ressourceType) => (
                      <div
                        className="relative flex items-start"
                        key={ressourceType.ressource_id}
                      >
                        <div className="flex h-5 items-center">
                          <input
                            id={ressourceType.name}
                            name="possible_ressources"
                            value={ressourceType.name}
                            aria-describedby="comments-description"
                            type="checkbox"
                            className="h-4 w-4 rounded border-gray-300 text-orange-600 focus:ring-orange-500"
                            onChange={handleRessourceTypeChange}
                            defaultChecked={ressource?.available_ressources.includes(
                              ressourceType.name
                            )}
                            disabled={ressource?.bookings[0]}
                          />
                        </div>
                        <div className="ml-3 text-sm">
                          <label
                            htmlFor={ressourceType.name}
                            className="font-medium text-gray-700"
                          >
                            {ressourceType.name}
                          </label>
                        </div>
                      </div>
                    ))}
                    <p className="text-xs leading-5 text-gray-500">
                      Die hier verfügbaren Varianten können in den
                      <a href="Settings#variants">Einstellungen</a> bearbeitet
                      werden.
                    </p>
                  </fieldset>
                  <div className="pt-3">
                    <input
                      id="cbInternal"
                      name="internal"
                      type="checkbox"
                      className="h-4 w-4 rounded border-gray-300 text-orange-600 focus:ring-orange-500"
                      defaultChecked={ressource?.internal}
                    />
                    <label
                      htmlFor="cbInternal"
                      className="italic text-sm ml-3 font-medium text-gray-700"
                    >
                      Nur intern
                    </label>
                  </div>
                  <input
                    type="hidden"
                    id="timezone"
                    name="timezone"
                    value={Intl.DateTimeFormat().resolvedOptions().timeZone}
                  />
                </div>

                <div className="space-y-1">
                  <button
                    type="submit"
                    className="group relative flex w-full justify-center rounded-md border border-transparent bg-green-600 py-2 px-4 text-sm font-medium text-white hover:bg-stone-700 focus:outline-none focus:ring-2 focus:ring-stone-500 focus:ring-offset-2"
                  >
                    Speichern
                  </button>
                  <button
                    type="button"
                    onClick={() => modal.remove()}
                    className="group relative flex w-full justify-center rounded-md border border-transparent bg-red-800 py-2 px-4 text-sm font-medium text-white hover:bg-stone-700 focus:outline-none focus:ring-2 focus:ring-stone-500 focus:ring-offset-2"
                  >
                    Beenden
                  </button>
                </div>
              </form>
              {createTimeslotResponse && createTimeslotResponse.ok && (
                <p className="text-green-500">Termin wurde erstellt</p>
              )}
              {createTimeslotResponse && !createTimeslotResponse.ok && (
                <p className="text-red-500">Speichern fehlgeschlagen</p>
              )}
            </div>
          </div>
        </div>
      </div>
    </Dialog>
  );
});
